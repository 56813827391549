/*
 * JaCoCo Report Viewer, a web-based coverage report viewer
 * Copyright (C) 2018  Euxane TRAN-GIRARD
 *                     Adam NAILI
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

.App {
  margin: 1rem;
}

.App label {
  display: inline-block;
  width: 6rem;
}

.App ul {
  padding-left: 1.75rem;
}

.App ul[inline-list="true"] {
  display: inline;
  padding-left: 0.75rem;
}

.App ul[inline-list="true"] li {
  display: inline;
  list-style: none;
}

.App ul[inline-list="true"] li:not(:first-child) {
  padding-left: 0.75rem;
}

.App li[well-covered="true"] {
  color: green;
}

.App li[well-covered="false"] {
  color: red;
}

.App .listing {
  overflow-x: auto;
  padding: 0.5rem 0;
  background-color: #FAFAFA;
}

.App .listing li[well-covered] {
    font-weight: bold;
}

.App pre {
  margin: 0.1rem;
}
